import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import ReviewCard from "../components/ReviewCard";

import imgR1 from "../assets/image/jpeg/reviewer1.jpg";
import imgR2 from "../assets/image/jpeg/reviewer2.jpg";
import imgR3 from "../assets/image/jpeg/reviewer3.jpg";
import imgR4 from "../assets/image/jpeg/reviewer4.jpg";
import imgR5 from "../assets/image/jpeg/reviewer5.jpg";
import imgR6 from "../assets/image/jpeg/reviewer6.jpg";
import imgR7 from "../assets/image/jpeg/reviewer7.jpg";
import imgR8 from "../assets/image/jpeg/reviewer8.jpg";
import imgR9 from "../assets/image/jpeg/reviewer9.jpg";
import Helmet from "react-helmet"

const Testimonals = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail - Testimonials from our customers </title>
        <meta name="title" content="HotSnail - Testimonials from our customers " />
        <meta name="description" content="HotSnail - Testimonials from our customers about mail scanning, mail forwarding and mail redirection" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail - Testimonials from our customers " />
        <meta property="og:description" content="HotSnail - Testimonials from our customers about mail scanning, mail forwarding and mail redirection" />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://hotsnail.com.au/" />
        <meta property="twitter:title" content="HotSnail - Testimonials from our customers " />
        <meta property="twitter:description" content="HotSnail - Testimonials from our customers about mail scanning, mail forwarding and mail redirection" />
        <meta property="twitter:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />
      </Helmet>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">Reviews from customers</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="4" md="6" className="mb-5">
                <ReviewCard
                  name="Alys"
                  company="HotSnail Customer"
                  stars={5}
                >
                  "Hi Karl and team, We just wanted to say that we have been using your service for twelve months now and are very happy. We have been receiving everything that we expected to and promptly receive mail if it needs forwarding on to one of the many addresses that we may use. We couldn\'t be happier and so glad we signed up with Hotsnail. Please feel free to use these comments any way you wish and happy to recommend you."
                </ReviewCard>
              </Col>
              <Col lg="4" md="6" className="mb-5">
                <ReviewCard
                  name="Frances"
                  company="HotSnail Customer"
                  stars={5}
                >
                  "Dear Mike, Thank you so much the service is one of the best things I have used. It has made travelling so much easier not having to worry about mail and when it will catch up with you. I have told a number of friends about the service and would recommend it to anyone taking a extended break away from home."
                </ReviewCard>
              </Col>
              <Col lg="4" md="6" className="mb-5">
                <ReviewCard
                  name="Andrew"
                  company="HotSnail Customer"
                  stars={5}
                >
                  "We have finished our travels and will no longer require your excellent service. We will keep our account with you because we will travel again and it is worth using your service even if we go away for a month or so. I must say that we have been very happy with the service that you provide. We have been travelling for over two years (currently in Europe) and your service has enabled us to run our lives remotely! Andrew"
                </ReviewCard>
              </Col>
              <Col lg="4" md="6" className="mb-5">
                <ReviewCard
                  name="Manfred"
                  company="HotSnail Customer"
                  stars={5}
                >
                  "Thanks for the many years of excellent service"
                </ReviewCard>
              </Col>
              <Col lg="4" md="6" className="mb-5">
                <ReviewCard
                  name="Carl"
                  company="HotSnail Customer"
                  stars={5}
                >
                  "Thanks for a fantastic service. I am in Europe still, but thanks to you I can relax about all I have going on in Australia. Good stuff."
                </ReviewCard>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Testimonals;
